import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import Services from "../components/services"
import Contact from "../components/contact"
import PropTypes from 'prop-types';

const IndexPage = ({ data, location }) => (
  <Layout location={location} lang="fi">
    <SEO title="Tervetuloa" keywords={['Målare', 'Målarbeten', 'Fasadrenovering', 'Fasadtvätt', 'Vasa', 'Korsholm', 'Vasa med omnejd']} />
    <TopSection img={data.img.childImageSharp.fluid} title="SH-Maalaus OY" styleClass="default-background" lang ="fi"/>
    <Services location={location} lang="fi" />
    <Contact lang="fi" />
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object,
};

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default IndexPage